import React from 'react';
import DishCard from '../common/DishCard';

const OurDishes = () => {
  return (
    <section id="nuestrosPlatos" className="px-4 py-4">
      <div className="container mx-auto max-w-6xl">
        <div className="mb-28">
          <h2 className="text-4xl font-semibold leading-tight md:text-center lg:text-5xl">
            Descubre nuestros platos <span className="lg:hidden">desde</span>
            <span className="bg-mc-primary text-mc-primary-light font-bold rounded-md px-2 py-1 lg:relative lg:before:content-['desde'] before:absolute before:text-sm before:font-normal before:bg-mc-secondary before:px-2 before:py-0 before:rounded-md before:-top-3 before:left-9">$345</span>
          </h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
          <DishCard
            imgSrc="/img/nuestros-platos-01.png"
            title="Pollo arrollado con legumbres"
            calories="562"
            protein="45 gr"
            fats="24 gr"
            carbs="45 gr"
          />
          <DishCard
            imgSrc="/img/nuestros-platos-02.png"
            title="Carne al horno con papas"
            calories="707"
            protein="37 gr"
            fats="28 gr"
            carbs="75 gr"
          />
          <DishCard
            imgSrc="/img/nuestros-platos-03.png"
            title="Pollo salteado con arroz"
            calories="363"
            protein="26 gr"
            fats="9 gr"
            carbs="45 gr"
          />
          <DishCard
            imgSrc="/img/nuestros-platos-04.png"
            title="Tortilla de papas con ensalada"
            calories="414"
            protein="14 gr"
            fats="24 g"
            carbs="35 g"
          />
        </div>

        <div className="mt-14 mb-14">
          <h4 className="text-lg font-normal mb-6 md:text-center md:text-xl">
            Buscamos la excelencia, desde los ingredientes que elegimos hasta cómo te lo entregamos.
          </h4>
          {/*<a href="/precios" target="_self" className="mc-btn mc-btn-primary w-full md:hidden">Ver precios y planes</a>*/}

          <div className="hidden md:flex justify-center gap-x-5 lg:bg-[url('../src/img/nuestros-platos-05.svg')] bg-no-repeat bg-center bg-contain">
            <div>
              <a href="/plan-ia" target="_self" className="mc-btn mc-btn-primary min-w-60">Generar con IA</a>
            </div>
            {/*<div>
              <a href="/precios" target="_self" className="mc-btn mc-btn-primary-outline min-w-60">Ver precios y planes</a>
            </div>*/}
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurDishes;
