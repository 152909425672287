import React, { useState } from "react";

const PreciosCard = ({title,description,five,ten,twenty,fourty}) => {
  const [open,setOpen] = useState(false);
  return (
    <article class="accordionItem bg-white rounded-xl p-6 shadow-lg">
        <div class="accordionHeader flex justify-between gap-4 cursor-pointer">
            <div class="relative flex-auto">
                <h3 class="font-bold text-mc-base/80 text-xl leading-none mb-2">Plan {title}</h3>
                <div class="relative">
                    <p class="font-bold text-mc-primary text-lg">
                        {fourty ? `Desde ${fourty.desde}` : "Consultar"}
                    </p>
                    <div class="absolute right-0 w-full h-auto lg:bottom-3 lg:max-w-[70%]">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 284 2" fill="none">
                            <path d="M0.5 1H283.5" stroke="url(#paint0_linear_698_97)" stroke-linecap="round"/>
                            <defs>
                            <linearGradient id="paint0_linear_698_97" x1="0.5" y1="1.5" x2="283.5" y2="1.5" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#FB7447"/>
                                <stop offset="1" stop-color="#FB7447" stop-opacity="0"/>
                            </linearGradient>
                            </defs>
                        </svg>
                    </div>
                </div>
            </div>
            <div class="flex-auto w-full h-full max-w-9 max-h-9">
                <button type="button" class="flex h-9 w-9 items-center justify-center" onClick={() => setOpen(!open)}>
                    {!open && <svg class="iconOpen" viewBox="0 0 37 37" fill="none">
                        <circle cx="18.5" cy="18.5" r="18.5" fill="#FF6F4E"/>
                        <path d="M25.328 20.176H20.456V24.936H16.844V20.176H12V16.76H16.844V12H20.456V16.76H25.328V20.176Z" fill="#FFFEFA"/>
                    </svg>}
                    {open && <svg class="iconClose" viewBox="0 0 37 37" fill="none">
                        <circle cx="18.5" cy="18.5" r="18.5" fill="#FF6F4E"/>
                        <path d="M25 20H12V17H25V20Z" fill="#FFFEFA"/>
                    </svg>}
                </button>
            </div>
        </div>
        <div class={`accordionContent ${!open ? 'max-h-0' : 'max-h-100'} overflow-hidden ${open ? 'open' : null}`}>
            <p class="my-4">{description}</p>
            <div class="flex flex-col gap-4 lg:flex-row mb-7">
                {five && ten && twenty && fourty && <div>
                    <a href="./plan-ia" class="mc-btn mc-btn-primary">Generar plan con IA</a>
                </div>}
                <div style={{marginLeft: '2px'}}>
                    <a href={`https://wa.me/+59898325975?text=Hola,%20me%20gustaría%20contratar%20el%20plan%20%2A${title.replace(" ","%20").toUpperCase()}%2A.%20Gracias!`} class="mc-btn mc-btn-primary-outline">Contactar</a>
                </div>
            </div>
            {five && ten && twenty && fourty && <div>
                <div class="flex justify-between mb-3">
                    <div>
                        <h3 class="text-xl text-mc-base font-semibold leading-none">Mensual 20 platos</h3>
                        <p class="text-mc-base text-xs">Almuerzo <span class="font-semibold">${twenty.desde} cada plato</span></p>
                    </div>
                    <div class="font-bold text-mc-base text-xl">${twenty.plan}</div>
                </div>
                <div class="flex justify-between mb-3">
                    <div>
                        <h3 class="text-xl text-mc-base font-semibold leading-none">Mensual 40 platos</h3>
                        <p class="text-mc-base text-xs">Almuerzo y Cena <span class="font-semibold">${fourty.desde} cada plato</span></p>
                    </div>
                    <div class="font-bold text-mc-base text-xl">${fourty.plan}</div>
                </div>
                <div class="flex justify-between mb-3">
                    <div>
                        <h3 class="text-xl text-mc-base font-semibold leading-none">Semanal 5 platos</h3>
                        <p class="text-mc-base text-xs">Almuerzo <span class="font-semibold">${five.desde} cada plato</span></p>
                    </div>
                    <div class="font-bold text-mc-base text-xl">${five.plan}</div>
                </div>
                <div class="flex justify-between mb-3">
                    <div>
                        <h3 class="text-xl text-mc-base font-semibold leading-none">Semanal 10 platos</h3>
                        <p class="text-mc-base text-xs">Almuerzo y Cena <span class="font-semibold">${ten.desde} cada plato</span></p>
                    </div>
                    <div class="font-bold text-mc-base text-xl">${ten.plan}</div>
                </div>
            </div>}
        </div>
    </article>
  );
};

export default PreciosCard;
