import React from 'react';
import { Link } from 'react-router-dom';

const Footer = ({howItWorksScroll}) => {
  return (
    <footer id="footerContent" className="px-4 bg-mc-primary rounded-t-3xl">
      <div className="container mx-auto max-w-6xl">
        <div className="flex flex-col items-center md:items-start md:flex-row pt-12 pb-12 text-white gap-4 text-lg">
          <LogoAndApps />
          <MainLinks howItWorksScroll={howItWorksScroll} />
          <SupportSection />
          <FollowUs />
        </div>
        <div className="text-white p-4 text-center text-sm">
          <p>
            &copy;2024 Mejor Casero
          </p>
        </div>
      </div>
    </footer>
  );
};

const LogoAndApps = () => {
  return (
    <div className="flex-auto">
      <div className="mb-10">
        <img src="/img/footer-logo.svg" alt="logo mejor casero" className="w-full max-w-[220px] mx-auto md:mx-0" />
      </div>
      <div className="mb-16 md:mb-0">
        <p className="font-semibold mb-3 text-center md:text-left">Descarga nuestra app</p>
        <div className="flex gap-4 w-full max-w-60">
          <a href="https://play.google.com/store/apps/details?id=com.mejorcasero.mejorcasero" className="hover:drop-shadow-xl">
            <img src="/img/btn-google-play.svg" alt="Google Play" className="w-full" />
          </a>
          <a href="https://apps.apple.com/uy/app/mejor-casero/id1525306383" className="hover:drop-shadow-xl">
            <img src="/img/btn-app-store.svg" alt="App Store" className="w-full" />
          </a>
        </div>
      </div>
    </div>
  );
};

const MainLinks = ({howItWorksScroll}) => {
  return (
    <div className="flex-auto order-1 md:-order-none relative">
      <div className="hidden md:block absolute right-5 top-10">
        <svg xmlns="http://www.w3.org/2000/svg" width="2" height="148" viewBox="0 0 2 148" fill="none">
            <path d="M1 0.5V147.5" stroke="url(#paint0_linear_68_837)"/>
            <defs>
              <linearGradient id="paint0_linear_68_837" x1="1.5" y1="0.5" x2="1.5" y2="147.5" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FFFEFA"/>
                <stop offset="1" stop-color="#999896" stop-opacity="0"/>
              </linearGradient>
            </defs>
        </svg>
      </div>
      <div>
        <div className="mb-6">
          <p className="font-semibold">Menu Principal</p>
        </div>
        <ul className="text-center md:text-left">
          <li><Link onClick={() => howItWorksScroll()} className="hover:text-mc-base">Cómo funciona</Link></li>
          {/*<li><a href="/precios" target="_self" className="hover:text-mc-base">Precios</a></li>*/}
          <li><a href="/nutricionistas" target="_self" className="hover:text-mc-base">Nutricionistas</a></li>
          <li><a href="/plan-ia" target="_self" className="hover:text-mc-base">Plan de viandas</a></li>
          {/*<li><Link to="/contacto" className="hover:text-mc-base">Contacto</Link></li>*/}
        </ul>
      </div>
    </div>
  );
};

const SupportSection = () => {
  return (
    <div className="flex-auto mb-10 relative">
      <div className="hidden md:block absolute right-12 top-10">
        <svg xmlns="http://www.w3.org/2000/svg" width="2" height="148" viewBox="0 0 2 148" fill="none">
            <path d="M1 0.5V147.5" stroke="url(#paint0_linear_68_837)"/>
            <defs>
              <linearGradient id="paint0_linear_68_837" x1="1.5" y1="0.5" x2="1.5" y2="147.5" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FFFEFA"/>
                <stop offset="1" stop-color="#999896" stop-opacity="0"/>
              </linearGradient>
            </defs>
        </svg>
      </div>
      <div className="mb-6 text-center md:text-left">
        <p className="font-semibold">Contamos con el apoyo</p>
      </div>
      <div className="mb-6 pt-1">
        <img src="/img/footer-ande.svg" alt="logo ANDE" className="mx-auto md:mx-0" />
      </div>
      <div>
        <img src="/img/footer-ort-cie.svg" alt="logos ORT - CIE" />
      </div>
    </div>
  );
};

const FollowUs = () => {
  return (
    <div className="flex-none">
      <div className="mb-6">
        <p className="font-semibold uppercase text-center md:text-left">Síguenos en</p>
      </div>
      <div class="flex gap-4 mb-6">
        {/*<a href="https://www.facebook.com/MejorCasero" class="rounded-md hover:bg-mc-primary-dark/25">
            <svg width="52" height="52" viewBox="0 0 52 52" fill="none">
                <rect x="0.5" y="0.5" width="51" height="51" rx="4.5" stroke="#FFFEFA"/><path fill-rule="evenodd" clip-rule="evenodd" d="M28.6854 37.9023V25.5875H32.3946L33.0004 20.1143H28.6854V17.4485C28.6854 16.0391 28.7212 14.6411 30.675 14.6411H32.6539V10.7279C32.6539 10.669 30.954 10.5361 29.2344 10.5361C25.643 10.5361 23.3942 12.8037 23.3942 16.9675V20.1143H19.4258V25.5875H23.3942V37.9023H28.6854Z" fill="#FFFEFA"/>
            </svg>
        </a>*/}
        <a href="https://www.instagram.com/mejorcaserouy/" class="rounded-md hover:bg-mc-primary-dark/25">
            <svg width="52" height="52" viewBox="0 0 52 52" fill="none">
                <rect x="0.5" y="0.5" width="51" height="51" rx="4.5" stroke="#FFFEFA"/><path fill-rule="evenodd" clip-rule="evenodd" d="M21.0436 12.1473C19.0901 12.2354 17.397 12.713 16.0298 14.0744C14.6578 15.443 14.1862 17.1428 14.0978 19.076C14.0429 20.2825 13.7217 29.3979 14.6531 31.7884C15.2811 33.4011 16.5181 34.6411 18.1456 35.2712C18.905 35.5666 19.7719 35.7666 21.0436 35.8249C31.6766 36.3061 35.6181 36.0442 37.2802 31.7884C37.5751 31.0309 37.7781 30.1647 37.8343 28.8962C38.3202 18.236 37.7555 15.9254 35.9023 14.0744C34.4324 12.6082 32.7034 11.6101 21.0436 12.1473ZM21.1415 33.681C19.9773 33.6286 19.3456 33.4346 18.9241 33.2714C17.8638 32.8593 17.0674 32.0662 16.6578 31.0121C15.9486 29.1956 16.1838 20.5686 16.2471 19.1726C16.3092 17.8053 16.5862 16.5556 17.551 15.5908C18.745 14.3997 20.2877 13.816 30.7918 14.29C32.1625 14.352 33.4151 14.6284 34.3823 15.5908C35.5763 16.7819 36.1686 18.3365 35.6862 28.8001C35.6336 29.9615 35.439 30.5916 35.2754 31.0121C34.1948 33.7813 31.7088 34.1657 21.1415 33.681ZM30.9076 17.6275C30.9076 18.416 31.5488 19.0572 32.3405 19.0572C33.1321 19.0572 33.7745 18.416 33.7745 17.6275C33.7745 16.839 33.1321 16.1984 32.3405 16.1984C31.5488 16.1984 30.9076 16.839 30.9076 17.6275ZM19.8352 23.9855C19.8352 27.3634 22.5803 30.1021 25.9666 30.1021C29.353 30.1021 32.0981 27.3634 32.0981 23.9855C32.0981 20.6076 29.353 17.8707 25.9666 17.8707C22.5803 17.8707 19.8352 20.6076 19.8352 23.9855ZM21.9869 23.9855C21.9869 21.7939 23.7684 20.0158 25.9666 20.0158C28.1649 20.0158 29.9464 21.7939 29.9464 23.9855C29.9464 26.1783 28.1649 27.957 25.9666 27.957C23.7684 27.957 21.9869 26.1783 21.9869 23.9855Z" fill="#FFFEFA"/>
            </svg>
        </a>
        {/*<a href="https://www.linkedin.com/company/mejor-casero/" class="rounded-md hover:bg-mc-primary-dark/25">
            <svg width="52" height="52" viewBox="0 0 52 52" fill="none">
                <rect x="0.5" y="0.5" width="51" height="51" rx="4.5" stroke="#FFFEFA"/><path d="M17.5009 20.6688H22.1741V33.9999H17.5009V20.6688ZM19.8375 19.193C18.9849 19.193 18.2962 18.9553 17.7714 18.4797C17.2467 18.0042 16.9844 17.4139 16.9844 16.7088C16.9844 16.0037 17.2467 15.4134 17.7714 14.9379C18.2962 14.4624 18.9849 14.2246 19.8375 14.2246C20.6902 14.2246 21.3789 14.4542 21.9036 14.9133C22.4283 15.356 22.6907 15.9299 22.6907 16.635C22.6907 17.3729 22.4283 17.9878 21.9036 18.4797C21.3789 18.9553 20.6902 19.193 19.8375 19.193Z" fill="white"/><path d="M33.8936 20.4474C35.5497 20.4474 36.8779 20.9393 37.8782 21.9232C38.8948 22.907 39.4031 24.391 39.4031 26.3751V33.9999H34.7299V27.1376C34.7299 25.3174 34.0002 24.4074 32.5408 24.4074C31.7374 24.4074 31.0897 24.6697 30.5977 25.1945C30.1222 25.7192 29.8844 26.5063 29.8844 27.5557V33.9999H25.2112V20.6688H29.6631V22.12C30.1878 21.5788 30.8109 21.1689 31.5324 20.8901C32.2539 20.595 33.0409 20.4474 33.8936 20.4474Z" fill="white"/>
              </svg>
        </a>*/}
      </div>
      {/*<div className="mb-12 md:mb-0">
        <Link to="/contacto" className="mc-btn mc-btn-secondary">Deja tu consulta</Link>
      </div>*/}
    </div>
  );
};

export default Footer;
